<template>
  <div v-if="image && label" class="bonus-tooltip">
    <atomic-image :src="image" class="bonus-tooltip__img" />
    <span class="bonus-tooltip__label">{{ label }}</span>
  </div>
</template>

<script setup lang="ts">
  const { globalComponentsContent, defaultLocaleGlobalComponentsContent } = useGlobalStore();
  const { getContent } = useProjectMethods();

  const image = computed(() =>
    getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'commonContent.bonusTooltip.image')
  );

  const label = computed(() =>
    getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'commonContent.bonusTooltip.label')
  );
</script>

<style src="assets/styles/components/sign-up/bonus-tooltip.scss" lang="scss" />
