<template>
  <notifications :position="alertPosition" :duration="8000" :speed="200" :width="alertWidth">
    <template #body="{ item, close }">
      <div v-if="item.text" class="alert">
        <p class="alert__text" v-html="item.text || ''" />
        <atomic-icon id="close" class="alert__close" @click="close" />
      </div>
    </template>
  </notifications>
</template>

<script setup lang="ts">
  const alertWidth = computed(() => (window.innerWidth < 768 ? window.innerWidth - 32 : 358));
  const alertPosition = computed(() => (window.innerWidth < 768 ? 'top center' : 'top right'));
</script>

<style src="~/assets/styles/components/atomic/alert.scss" lang="scss" />
